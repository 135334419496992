import React from 'react';

import './HeaderComponent.css'

export const HeaderComponent = () => {
  return (
    <div className="header">
        <h1>SMS</h1>
        <p>Secret Message Sender</p>
    </div>
  );
}


