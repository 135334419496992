import React from 'react';

import './FooterComponent.css'

export const FooterComponent = () => {
  return (
    <div className="footer">
        <p>Secret Message Sender</p>
    </div>
  );
}


